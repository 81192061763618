import { SystemPageKey } from '@internal/page/SystemPageKey'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { match } from 'path-to-regexp'
import { ParsedUrlQuery } from 'querystring'
import { PageTypeKey } from '../page/PageTypeKey'
import { TMachineCategoryPageRouteMatchResult } from './machineCategoryPages/TMachineCategoryPageRouteMatchResult'
import { TRedirect } from './redirects/TRedirect'
import { routingTable } from './routingTable'
import { TSystemPageRouteMatchResult } from './systemPages/TSystemPageRouteMatchResult'

export const matchSystemPage = (
  url: string,
  queryParams: ParsedUrlQuery,
  options?: { include: SystemPageKey[] } | { exclude: SystemPageKey[] }
) => {
  const keys = Object.values(SystemPageKey)
  for (let key of keys) {
    if (
      options &&
      (('include' in options && !options.include.includes(key)) ||
        ('exclude' in options && options.exclude.includes(key)))
    ) {
      continue
    }
    const routes = Object.values(routingTable.systemPageRoutes[key])

    for (let systemPageRoute of routes) {
      const matchResult = systemPageRoute.exec(url, queryParams)

      if (matchResult) {
        return matchResult as TSystemPageRouteMatchResult
      }
    }
  }

  return false
}

export const matchMachineCategoryPage = (
  url: string,
  locale: TLocale,
  queryParams: ParsedUrlQuery
) => {
  const result = routingTable.machineCategoryPageRoutes[locale].exec(
    url,
    queryParams
  )
  if (result) {
    return result as TMachineCategoryPageRouteMatchResult
  }

  return false
}

export const matchRedirect = async (params: {
  type: 'serp' | 'short-pdp' | 'other'
  url: string
  queryParams: ParsedUrlQuery
  locale: TLocale
  ip: string | null
}) => {
  let redirectsList: TRedirect[] = []

  switch (params.type) {
    case 'serp':
      redirectsList = routingTable.searchResultsPage
      break
    case 'short-pdp':
      redirectsList = [routingTable.shortPdp]
      break
    case 'other':
      redirectsList = routingTable.redirects
      break
  }

  for (let redirect of redirectsList) {
    const matchResult = await redirect.exec({
      url: params.url,
      queryParams: params.queryParams,
      locale: params.locale,
      ip: params.ip,
    })
    if (matchResult) {
      return matchResult
    }
  }

  return false
}

export const parseMachineUrl = (url: string, locale: TLocale) => {
  const routeMatchResult = routingTable.machineRoutes.nonStrictPdp[locale].exec(
    url,
    {}
  )
  if (
    routeMatchResult !== false &&
    routeMatchResult.type === PageTypeKey.SystemPage &&
    routeMatchResult.systemPageKey === SystemPageKey.PDP
  ) {
    routeMatchResult.pathParams.machineGroupId

    return {
      machineGroupId: routeMatchResult.pathParams.machineGroupId,
    }
  }

  const matcher = match<{ machineGroupId?: string }>(
    routingTable.machineRoutes.short,
    {
      sensitive: false,
    }
  )

  const matcherResult = matcher(url)
  if (matcherResult !== false) {
    return {
      machineGroupId: matcherResult.params.machineGroupId,
    }
  }

  return null
}
