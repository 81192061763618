import { getMachineMeta, MachineMeta } from '@internal/services/apiClient'
import { NotFoundMachineLoader } from '@renderer-ui-library/molecules'
import { CircularProgress } from '@renderer-ui-library/mui'
import { BlockProps } from 'blocks/BlockProps'
import { BlockType } from 'blocks/BlockType'
import { TextImageSplitBlock } from 'blocks/TextImageSplitBlock/TextImageSplitBlock'
import React, { useCallback, useEffect, useState } from 'react'
import * as router from 'utils/routing/router'
import { INotFoundBlockData } from './INotFoundBlockData'
import styles from './notFoundBlock.module.scss'

export const NotFoundBlock: React.FC<BlockProps<INotFoundBlockData>> =
  React.memo((props) => {
    const [displayType, setDisplayType] = useState<
      'loading' | 'generic' | 'machine'
    >('loading')

    const [machine, setMachine] = useState<MachineMeta | null>(null)

    const switchView = useCallback(
      (view: 'generic' | 'machine') => setDisplayType(view),
      []
    )

    useEffect(() => {
      const result = router.parseMachineUrl(location.pathname, props.locale)
      if (!result?.machineGroupId) {
        switchView('generic')
        return
      }

      void getMachineMeta({ machineGroupId: result.machineGroupId })
        .then((machine) => {
          setMachine(machine)
          switchView('machine')
        })
        .catch(() => switchView('generic'))
    }, [props.locale, switchView])

    return (
      <>
        {displayType === 'loading' && (
          <div className={styles.loading}>
            <CircularProgress size={60} />
          </div>
        )}
        {displayType === 'machine' && machine && (
          <div className={styles.machine}>
            <NotFoundMachineLoader machine={machine} locale={props.locale} />
          </div>
        )}
        {displayType === 'generic' && (
          <TextImageSplitBlock
            {...{
              ...props,
              data: {
                ...props.data,
                type: BlockType.TextImageSplitBlock,
              },
            }}
          />
        )}
      </>
    )
  })

NotFoundBlock.displayName = 'NotFoundBlock'
